import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contact: file(
      relativePath: { eq: "contact-email.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={['blog', 'gatsby', 'javascript', 'react']} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <Img
            fluid={data.contact.childImageSharp.fluid}
          />
        </div>
      </article>
    </Layout>
  );
};

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);